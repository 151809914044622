<template>
  <div id="community">
    <img src="@/assets/images/jxxbt_poster.png" />
  </div>
</template>

<script>
export default {
  name: "community",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
#community {
  background: #ff5c05;
  min-width: 300px;
  height: 100%;
  img {
    width: 100%;
    display: block;
  }
}
</style>
